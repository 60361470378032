@import '~react-pro-sidebar/dist/css/styles.css';

:root {
  --my-bg: gray;
  --my-col: #ffffff;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

.auth-box {
  width: 450px;
  height: auto;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px var(--my-bg);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.form-control:focus {
  border-color: #a3c4bc !important;
}

.subHead {
  background-color: var(--my-bg);
  margin: 10px;
  color: white;
  text-align: left;
  padding: 5px 5px 5px 5px;
  font-weight: bold;
  width: -webkit-fill-available;
}

.float-Aside {
  position: sticky -webkit-sticky;
  top: 0px;
  left: 0px;
  /*
  width:60px;
  height:60px;
  background-color:green;
  color:#FFF;
  border-radius:50px;
  border-color: gray;
  text-align:center;
  box-shadow: 2px 2px 3px #999;*/
}

.float-center {
  position: fixed;
  top: 50%;
  right: 50%;
}

.float-bottom-right {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 10px;
  right: 20px;
  background-color: #1d1d1d;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  border: none;
  /*
  border-color: gray;
  box-shadow: 2px 2px 3px #999;
  */
}

.main-body {
  height: calc(100% - 52px);
  overflow: auto;
}

.body-box {
  margin: calc(100%-10px);
  box-shadow: gray;
  padding: 10px;
}

.flex-box {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  height: auto;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.option-my {
  border-radius: 5px;
  border-color: gray;
  height: 30px;
}

.btn-my {
  padding: 10px;
  width: 100px;
  font-size: 20px;
  font-weight: bold;
  font-family: Verdana, sans-serif;
}

.icon-btn {
  margin: 10px !important;
  border-color: gray !important;
  background-color: white !important;
  color: gray !important;
  width: auto;
}

.border-UL {
  border-radius: 0% !important;
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

.border-UL:focus {
  border-radius: 0% !important;
  box-shadow: inset !important;
}

.closemenu {
  color: rgb(255, 255, 255);
  position: absolute;
  right: 2px;
  z-index: 9999;
  line-height: 20px;
  border-radius: 50%;
  font-weight: bold;
  font-size: 22px;
  top: 38px;
  cursor: pointer;
}

/* Modal CSS */

.modal-90w {
  max-width: 90% !important;
}

.edit-btn {
  background: white !important;
  border: white !important;
  color: gray !important;
}

.inp-ul {
  border-top: none;
  border-right: none;
  border-left: none;
}

.common-row {
  padding: 10px;
}

.col-card {
  max-width: 180px;
  padding: 5px;
}
.horizontal-card {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.react-switch {
  margin-top: 20px;
  margin-left: 5px;
  padding: auto;
}

.card-content {
  margin-left: 10px;
  margin-top: 25px;
}
/* Refer https://getbootstrap.com/docs/5.2/components/pagination/
  .page-item.active .page-link {
    background-color: green;
    border-color: lightgreen;
  }
  
  .page-link {
    color: green;
  }
  
  .page-link:hover {
    color: green;
  }

  */