.accordion-button {
    border: none !important;
  }
  
.accordion-button:focus {
    background-color: gray !important;
    color: white !important;
    box-shadow: none !important;
    border: none !important;
  }
  
.accordion-button:not(.collapsed) {
  background-color: gray !important;
  color: white !important;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='black'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")!important;
}
.accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg)!important;
}