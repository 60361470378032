.Chat {
    position: fixed !important;
    right: 20px !important;
    bottom: 60px !important;
    border-radius: 5px !important;
    border: 1px;

}

.Chat-header {
    border-style: solid;
    border-color: #567572;
    border-radius: 5px;
    border-width: 1px;
}

.react-chatbot-kit-chat-container {
    border-radius: 5px;
}

.react-chatbot-kit-chat-inner-container {
    border-radius: 5px;
}

.react-chatbot-kit-chat-header {
    background-color: rgb(55, 107, 126);
    color: white;
}

.react-chatbot-kit-chat-input-container {
    background-color: gray;
}

.react-chatbot-kit-chat-message-container {
    background: #c7d3d4ff;
}

.react-chatbot-kit-chat-input {
    background-color: white;
}

.react-chatbot-kit-chat-input-form {
    background: #567572ff;
    color: white;
}

.react-chatbot-kit-chat-input::placeholder {
    color: gray;
}

.react-chatbot-kit-chat-btn-send {
    color: yellow;
}

.react-chatbot-kit-chat-btn-send-icon {
    color: yellow;
}
/*
.react-chatbot-kit-chat-bot-message-container {
    background-color: darkslateblue;
}

.react-chatbot-kit-chat-bot-avatar-container {
    background-color: darkslateblue;
}

.react-chatbot-kit-chat-bot-avatar-icon {
    color: darkslateblue;
}

.react-chatbot-kit-chat-bot-avatar-letter {}

.react-chatbot-kit-chat-bot-message {}

.react-chatbot-kit-chat-bot-message-arrow {}

.react-chatbot-kit-chat-bot-loading-icon-container {}

.chatbot-loader-container {}

#chatbot-loader #chatbot-loader-dot1 {}

#chatbot-loader #chatbot-loader-dot2 {}

#chatbot-loader #chatbot-loader-dot3 {}

.react-chatbot-kit-error {
    color: red;
}

.react-chatbot-kit-error-container {
    color: red;
}

.react-chatbot-kit-error-header {
    color: red;
}

.react-chatbot-kit-error-docs {
    color: red;
}
*/