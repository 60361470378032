 
  .bc-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: fit-content;
    height: 6px;
    margin: 0;
  }
  
  .bc-container:after {
    content: "";
    position: absolute;
    top: 0;
    left: 25px;
    height: 45px;
    width: 300px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
    background-color: #1d1d1d;
  }
  
  .bc-begin {
    height: 45px;
    width: 45px;
    border: 1px solid gray;
    background-color: #1d1d1d;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    z-index: 2;
  }
  .bc-icon {
    align-self: center;
  }
  .bc-item {
    display: flex;
    justify-content: center;
    height: 45px;
    width: 100px;
    border-bottom-right-radius: 55px; /* 100px of height + 10px of border */
    border-top-right-radius: 55px; /* 100px of height + 10px of border */
    border: 1px solid gray;
    border-left: 0;
    background-color: #1d1d1d;
    z-index: 2;
    color: white;
    font-size: 9px;
  }
  