
h5 {
  text-decoration-line: underline;
  text-decoration-color: white;
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: inherit;
  line-height: 1.1;
  color: rgb(116, 109, 109);
  display: block;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  }


.block-header {
  margin-bottom: 15px; 
  /*padding-top:0px;*/
}
  .block-header h2 {
    margin: 0 !important;
    color: #666 !important;
    font-weight: normal;
    font-size: 16px; }
    .block-header h2 small {
      display: block;
      font-size: 12px;
      margin-top: 8px;
      color: #888; }
      .block-header h2 small a {
        font-weight: bold;
        color: #777; }


/* Custom Colors */
.bg-red {
  background-color: #F44336 !important;
  color: #fff; }
  .bg-red .content .text,
  .bg-red .content .number {
    color: #fff !important; }

.bg-pink {
  background-color: #E91E63 !important;
  color: #fff; }
  .bg-pink .content .text,
  .bg-pink .content .number {
    color: #fff !important; }

.bg-purple {
  background-color: #9C27B0 !important;
  color: #fff; }
  .bg-purple .content .text,
  .bg-purple .content .number {
    color: #fff !important; }

.bg-deep-purple {
  background-color: #673AB7 !important;
  color: #fff; }
  .bg-deep-purple .content .text,
  .bg-deep-purple .content .number {
    color: #fff !important; }

.bg-indigo {
  background-color: #3F51B5 !important;
  color: #fff; }
  .bg-indigo .content .text,
  .bg-indigo .content .number {
    color: #fff !important; }

.bg-blue {
  background-color: #2196F3 !important;
  color: #fff; }
  .bg-blue .content .text,
  .bg-blue .content .number {
    color: #fff !important; }

.bg-light-blue {
  background-color: #03A9F4 !important;
  color: #fff; }
  .bg-light-blue .content .text,
  .bg-light-blue .content .number {
    color: #fff !important; }

.bg-cyan {
  background-color: #00BCD4 !important;
  color: #fff; }
  .bg-cyan .content .text,
  .bg-cyan .content .number {
    color: #fff !important; }

.bg-teal {
  background-color: #009688 !important;
  color: #fff; }
  .bg-teal .content .text,
  .bg-teal .content .number {
    color: #fff !important; }

.bg-green {
  background-color: #4CAF50 !important;
  color: #fff; }
  .bg-green .content .text,
  .bg-green .content .number {
    color: #fff !important; }

.bg-light-green {
  background-color: #8BC34A !important;
  color: #fff; }
  .bg-light-green .content .text,
  .bg-light-green .content .number {
    color: #fff !important; }

.bg-lime {
  background-color: #CDDC39 !important;
  color: #fff; }
  .bg-lime .content .text,
  .bg-lime .content .number {
    color: #fff !important; }

.bg-yellow {
  background-color: #ffe821 !important;
  color: #fff; }
  .bg-yellow .content .text,
  .bg-yellow .content .number {
    color: #fff !important; }

.bg-amber {
  background-color: #FFC107 !important;
  color: #fff; }
  .bg-amber .content .text,
  .bg-amber .content .number {
    color: #fff !important; }

.bg-orange {
  background-color: #FF9800 !important;
  color: #fff; }
  .bg-orange .content .text,
  .bg-orange .content .number {
    color: #fff !important; }

.bg-deep-orange {
  background-color: #FF5722 !important;
  color: #fff; }
  .bg-deep-orange .content .text,
  .bg-deep-orange .content .number {
    color: #fff !important; }

.bg-brown {
  background-color: #795548 !important;
  color: #fff; }
  .bg-brown .content .text,
  .bg-brown .content .number {
    color: #fff !important; }

.bg-grey {
  background-color: #9E9E9E !important;
  color: #fff; }
  .bg-grey .content .text,
  .bg-grey .content .number {
    color: #fff !important; }

.bg-blue-grey {
  background-color: #607D8B !important;
  color: #fff; }
  .bg-blue-grey .content .text,
  .bg-blue-grey .content .number {
    color: #fff !important; }

.bg-black {
  background-color: #000000 !important;
  color: #fff; }
  .bg-black .content .text,
  .bg-black .content .number {
    color: #fff !important; }

.bg-white {
  background-color: #ffffff !important;
  color: #fff; }
  .bg-white .content .text,
  .bg-white .content .number {
    color: #fff !important; }


.col-red {
  color: #F44336 !important; }

.col-pink {
  color: #E91E63 !important; }

.col-purple {
  color: #9C27B0 !important; }

.col-deep-purple {
  color: #673AB7 !important; }

.col-indigo {
  color: #3F51B5 !important; }

.col-blue {
  color: #2196F3 !important; }

.col-light-blue {
  color: #03A9F4 !important; }

.col-cyan {
  color: #00BCD4 !important; }

.col-teal {
  color: #009688 !important; }

.col-green {
  color: #4CAF50 !important; }

.col-light-green {
  color: #8BC34A !important; }

.col-lime {
  color: #CDDC39 !important; }

.col-yellow {
  color: #ffe821 !important; }

.col-amber {
  color: #FFC107 !important; }

.col-orange {
  color: #FF9800 !important; }

.col-deep-orange {
  color: #FF5722 !important; }

.col-brown {
  color: #795548 !important; }

.col-grey {
  color: #9E9E9E !important; }

.col-blue-grey {
  color: #607D8B !important; }

.col-black {
  color: #000000 !important; }

.col-white {
  color: #ffffff !important; }

/* Infobox ===================================== */
.info-box {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .info-box .icon {
    display: inline-block;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.12);
    width: 80px; }
    .info-box .icon i {
      color: #fff;
      font-size: 50px;
      line-height: 80px; }
    .info-box .icon .chart.chart-bar {
      height: 100%;
      line-height: 100px; }
      .info-box .icon .chart.chart-bar canvas {
        vertical-align: baseline !important; }
    .info-box .icon .chart.chart-pie {
      height: 100%;
      line-height: 123px; }
      .info-box .icon .chart.chart-pie canvas {
        vertical-align: baseline !important; }
    .info-box .icon .chart.chart-line {
      height: 100%;
      line-height: 115px; }
      .info-box .icon .chart.chart-line canvas {
        vertical-align: baseline !important; }
  .info-box .content {
    display: inline-block;
    padding: 7px 10px; }
    .info-box .content .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555; }
    .info-box .content .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555; }

.info-box.hover-zoom-effect .icon {
  overflow: hidden; }
  .info-box.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.info-box.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4); }

.info-box.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 80px;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s; }

.info-box.hover-expand-effect:hover:after {
  width: 100%; }

.info-box-2 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .info-box-2 .icon {
    display: inline-block;
    text-align: center;
    width: 80px; }
    .info-box-2 .icon i {
      color: #fff;
      font-size: 50px;
      line-height: 80px; }
  .info-box-2 .chart.chart-bar {
    height: 100%;
    line-height: 105px; }
    .info-box-2 .chart.chart-bar canvas {
      vertical-align: baseline !important; }
  .info-box-2 .chart.chart-pie {
    height: 100%;
    line-height: 123px; }
    .info-box-2 .chart.chart-pie canvas {
      vertical-align: baseline !important; }
  .info-box-2 .chart.chart-line {
    height: 100%;
    line-height: 115px; }
    .info-box-2 .chart.chart-line canvas {
      vertical-align: baseline !important; }
  .info-box-2 .content {
    display: inline-block;
    padding: 7px 10px; }
    .info-box-2 .content .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555; }
    .info-box-2 .content .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555; }

.info-box-2.hover-zoom-effect .icon {
  overflow: hidden; }
  .info-box-2.hover-zoom-effect .icon i {
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease; }

.info-box-2.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4); }

.info-box-2.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s; }

.info-box-2.hover-expand-effect:hover:after {
  width: 100%; }

.info-box-3 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .info-box-3 .icon {
    position: absolute;
    right: 10px;
    bottom: 2px;
    text-align: center; }
    .info-box-3 .icon i {
      color: rgba(0, 0, 0, 0.15);
      font-size: 60px; }
  .info-box-3 .chart {
    margin-right: 5px; }
  .info-box-3 .chart.chart-bar {
    height: 100%;
    line-height: 50px; }
    .info-box-3 .chart.chart-bar canvas {
      vertical-align: baseline !important; }
  .info-box-3 .chart.chart-pie {
    height: 100%;
    line-height: 34px; }
    .info-box-3 .chart.chart-pie canvas {
      vertical-align: baseline !important; }
  .info-box-3 .chart.chart-line {
    height: 100%;
    line-height: 40px; }
    .info-box-3 .chart.chart-line canvas {
      vertical-align: baseline !important; }
  .info-box-3 .content {
    display: inline-block;
    padding: 7px 16px; }
    .info-box-3 .content .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555; }
    .info-box-3 .content .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555; }

.info-box-3.hover-zoom-effect .icon i {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.info-box-3.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4); }

.info-box-3.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s; }

.info-box-3.hover-expand-effect:hover:after {
  width: 100%; }

.info-box-4 {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  height: 80px;
  display: flex;
  cursor: default;
  background-color: #fff;
  position: relative;
  overflow: hidden;
  margin-bottom: 30px; }
  .info-box-4 .icon {
    position: absolute;
    right: 10px;
    bottom: 2px;
    text-align: center; }
    .info-box-4 .icon i {
      color: rgba(0, 0, 0, 0.15);
      font-size: 60px; }
  .info-box-4 .chart {
    margin-right: 5px; }
  .info-box-4 .chart.chart-bar {
    height: 100%;
    line-height: 50px; }
    .info-box-4 .chart.chart-bar canvas {
      vertical-align: baseline !important; }
  .info-box-4 .chart.chart-pie {
    height: 100%;
    line-height: 34px; }
    .info-box-4 .chart.chart-pie canvas {
      vertical-align: baseline !important; }
  .info-box-4 .chart.chart-line {
    height: 100%;
    line-height: 40px; }
    .info-box-4 .chart.chart-line canvas {
      vertical-align: baseline !important; }
  .info-box-4 .content {
    display: inline-block;
    padding: 7px 16px; }
    .info-box-4 .content .text {
      font-size: 13px;
      margin-top: 11px;
      color: #555; }
    .info-box-4 .content .number {
      font-weight: normal;
      font-size: 26px;
      margin-top: -4px;
      color: #555; }

.info-box-4.hover-zoom-effect .icon i {
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease; }

.info-box-4.hover-zoom-effect:hover .icon i {
  opacity: 0.4;
  -moz-transform: rotate(-32deg) scale(1.4);
  -ms-transform: rotate(-32deg) scale(1.4);
  -o-transform: rotate(-32deg) scale(1.4);
  -webkit-transform: rotate(-32deg) scale(1.4);
  transform: rotate(-32deg) scale(1.4); }

.info-box-4.hover-expand-effect:after {
  background-color: rgba(0, 0, 0, 0.05);
  content: ".";
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  color: transparent;
  -moz-transition: all 0.95s;
  -o-transition: all 0.95s;
  -webkit-transition: all 0.95s;
  transition: all 0.95s; }

.info-box-4.hover-expand-effect:hover:after {
  width: 100%; }

  /* Card ======================================== */
.card {
  background: #fff;
  min-height: 50px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
  position: relative;
  margin-bottom: 30px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px; }
  .card .card-inside-title {
    margin-top: 25px;
    margin-bottom: 15px;
    display: block;
    font-size: 15px;
    color: #000; }
    .card .card-inside-title small {
      color: #999;
      display: block;
      font-size: 11px;
      margin-top: 5px; }
      .card .card-inside-title small a {
        color: #777;
        font-weight: bold; }
  .card .card-inside-title:first-child {
    margin-top: 0; }
  .card .bg-red,
  .card .bg-pink,
  .card .bg-purple,
  .card .bg-deep-purple,
  .card .bg-indigo,
  .card .bg-blue,
  .card .bg-light-blue,
  .card .bg-cyan,
  .card .bg-teal,
  .card .bg-green,
  .card .bg-light-green,
  .card .bg-lime,
  .card .bg-yellow,
  .card .bg-amber,
  .card .bg-orange,
  .card .bg-deep-orange,
  .card .bg-brown,
  .card .bg-grey,
  .card .bg-blue-grey,
  .card .bg-black {
    border-bottom: none !important;
    color: #fff !important; }
    .card .bg-red h2, .card .bg-red small, .card .bg-red .material-icons,
    .card .bg-pink h2,
    .card .bg-pink small,
    .card .bg-pink .material-icons,
    .card .bg-purple h2,
    .card .bg-purple small,
    .card .bg-purple .material-icons,
    .card .bg-deep-purple h2,
    .card .bg-deep-purple small,
    .card .bg-deep-purple .material-icons,
    .card .bg-indigo h2,
    .card .bg-indigo small,
    .card .bg-indigo .material-icons,
    .card .bg-blue h2,
    .card .bg-blue small,
    .card .bg-blue .material-icons,
    .card .bg-light-blue h2,
    .card .bg-light-blue small,
    .card .bg-light-blue .material-icons,
    .card .bg-cyan h2,
    .card .bg-cyan small,
    .card .bg-cyan .material-icons,
    .card .bg-teal h2,
    .card .bg-teal small,
    .card .bg-teal .material-icons,
    .card .bg-green h2,
    .card .bg-green small,
    .card .bg-green .material-icons,
    .card .bg-light-green h2,
    .card .bg-light-green small,
    .card .bg-light-green .material-icons,
    .card .bg-lime h2,
    .card .bg-lime small,
    .card .bg-lime .material-icons,
    .card .bg-yellow h2,
    .card .bg-yellow small,
    .card .bg-yellow .material-icons,
    .card .bg-amber h2,
    .card .bg-amber small,
    .card .bg-amber .material-icons,
    .card .bg-orange h2,
    .card .bg-orange small,
    .card .bg-orange .material-icons,
    .card .bg-deep-orange h2,
    .card .bg-deep-orange small,
    .card .bg-deep-orange .material-icons,
    .card .bg-brown h2,
    .card .bg-brown small,
    .card .bg-brown .material-icons,
    .card .bg-grey h2,
    .card .bg-grey small,
    .card .bg-grey .material-icons,
    .card .bg-blue-grey h2,
    .card .bg-blue-grey small,
    .card .bg-blue-grey .material-icons,
    .card .bg-black h2,
    .card .bg-black small,
    .card .bg-black .material-icons {
      color: #fff !important; }
    .card .bg-red .badge,
    .card .bg-pink .badge,
    .card .bg-purple .badge,
    .card .bg-deep-purple .badge,
    .card .bg-indigo .badge,
    .card .bg-blue .badge,
    .card .bg-light-blue .badge,
    .card .bg-cyan .badge,
    .card .bg-teal .badge,
    .card .bg-green .badge,
    .card .bg-light-green .badge,
    .card .bg-lime .badge,
    .card .bg-yellow .badge,
    .card .bg-amber .badge,
    .card .bg-orange .badge,
    .card .bg-deep-orange .badge,
    .card .bg-brown .badge,
    .card .bg-grey .badge,
    .card .bg-blue-grey .badge,
    .card .bg-black .badge {
      background-color: #fff;
      color: #555; }
  .card .header {
    color: #555;
    padding: 20px;
    position: relative;
    border-bottom: 1px solid rgba(204, 204, 204, 0.35); }
    .card .header .header-dropdown {
      position: absolute;
      top: 20px;
      right: 15px;
      list-style: none; }
      .card .header .header-dropdown .dropdown-menu li {
        display: block !important; }
      .card .header .header-dropdown li {
        display: inline-block; }
      .card .header .header-dropdown i {
        font-size: 20px;
        color: #999;
        -moz-transition: all 0.5s;
        -o-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
        .card .header .header-dropdown i:hover {
          color: #000; }
    .card .header h2 {
      margin: 0;
      font-size: 18px;
      font-weight: normal;
      color: #111; }
      .card .header h2 small {
        display: block;
        font-size: 12px;
        margin-top: 5px;
        color: #999;
        line-height: 15px; }
        .card .header h2 small a {
          font-weight: bold;
          color: #777; }
    .card .header .col-xs-12 h2 {
      margin-top: 5px; }
  .card .body {
    font-size: 14px;
    color: #555;
    padding: 20px; }
    .card .body .col-xs-1,
    .card .body .col-sm-1,
    .card .body .col-md-1,
    .card .body .col-lg-1 {
      margin-bottom: 20px; }
    .card .body .col-xs-2,
    .card .body .col-sm-2,
    .card .body .col-md-2,
    .card .body .col-lg-2 {
      margin-bottom: 20px; }
    .card .body .col-xs-3,
    .card .body .col-sm-3,
    .card .body .col-md-3,
    .card .body .col-lg-3 {
      margin-bottom: 20px; }
    .card .body .col-xs-4,
    .card .body .col-sm-4,
    .card .body .col-md-4,
    .card .body .col-lg-4 {
      margin-bottom: 20px; }
    .card .body .col-xs-5,
    .card .body .col-sm-5,
    .card .body .col-md-5,
    .card .body .col-lg-5 {
      margin-bottom: 20px; }
    .card .body .col-xs-6,
    .card .body .col-sm-6,
    .card .body .col-md-6,
    .card .body .col-lg-6 {
      margin-bottom: 20px; }
    .card .body .col-xs-7,
    .card .body .col-sm-7,
    .card .body .col-md-7,
    .card .body .col-lg-7 {
      margin-bottom: 20px; }
    .card .body .col-xs-8,
    .card .body .col-sm-8,
    .card .body .col-md-8,
    .card .body .col-lg-8 {
      margin-bottom: 20px; }
    .card .body .col-xs-9,
    .card .body .col-sm-9,
    .card .body .col-md-9,
    .card .body .col-lg-9 {
      margin-bottom: 20px; }
    .card .body .col-xs-10,
    .card .body .col-sm-10,
    .card .body .col-md-10,
    .card .body .col-lg-10 {
      margin-bottom: 20px; }
    .card .body .col-xs-11,
    .card .body .col-sm-11,
    .card .body .col-md-11,
    .card .body .col-lg-11 {
      margin-bottom: 20px; }
    .card .body .col-xs-12,
    .card .body .col-sm-12,
    .card .body .col-md-12,
    .card .body .col-lg-12 {
      margin-bottom: 20px; }

      /* Tables ====================================== */
.table tbody tr td, .table tbody tr th {
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }

.table tbody tr.primary td, .table tbody tr.primary th {
  background-color: #1f91f3;
  color: #fff; }

.table tbody tr.success td, .table tbody tr.success th {
  background-color: #2b982b;
  color: #fff; }

.table tbody tr.info td, .table tbody tr.info th {
  background-color: #00b0e4;
  color: #fff; }

.table tbody tr.warning td, .table tbody tr.warning th {
  background-color: #ff9600;
  color: #fff; }

.table tbody tr.danger td, .table tbody tr.danger th {
  background-color: #fb483a;
  color: #fff; }

.table thead tr th {
  padding: 10px;
  border-bottom: 1px solid #eee; }

.table-bordered {
  border-top: 1px solid #eee; }
  .table-bordered tbody tr td, .table-bordered tbody tr th {
    padding: 10px;
    border: 1px solid #eee; }
  .table-bordered thead tr th {
    padding: 10px;
    border: 1px solid #eee; }

    /* Modals ====================================== */
.modal .modal-header {
  border: none;
  padding: 25px 25px 5px 25px; }
  .modal .modal-header .modal-title {
    font-weight: bold;
    font-size: 16px; }

.modal .modal-content {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  border-radius: 0;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.31) !important;
  border: none; }
  .modal .modal-content .modal-body {
    color: #777;
    padding: 15px 25px; }

.modal .modal-footer {
  border: none; }

.modal-col-red {
  background-color: #F44336; }
  .modal-col-red .modal-body,
  .modal-col-red .modal-title {
    color: #fff !important; }
  .modal-col-red .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-red .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-red .modal-footer .btn-link:hover, .modal-col-red .modal-footer .btn-link:active, .modal-col-red .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-pink {
  background-color: #E91E63; }
  .modal-col-pink .modal-body,
  .modal-col-pink .modal-title {
    color: #fff !important; }
  .modal-col-pink .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-pink .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-pink .modal-footer .btn-link:hover, .modal-col-pink .modal-footer .btn-link:active, .modal-col-pink .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-purple {
  background-color: #9C27B0; }
  .modal-col-purple .modal-body,
  .modal-col-purple .modal-title {
    color: #fff !important; }
  .modal-col-purple .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-purple .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-purple .modal-footer .btn-link:hover, .modal-col-purple .modal-footer .btn-link:active, .modal-col-purple .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-deep-purple {
  background-color: #673AB7; }
  .modal-col-deep-purple .modal-body,
  .modal-col-deep-purple .modal-title {
    color: #fff !important; }
  .modal-col-deep-purple .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-deep-purple .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-deep-purple .modal-footer .btn-link:hover, .modal-col-deep-purple .modal-footer .btn-link:active, .modal-col-deep-purple .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-indigo {
  background-color: #3F51B5; }
  .modal-col-indigo .modal-body,
  .modal-col-indigo .modal-title {
    color: #fff !important; }
  .modal-col-indigo .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-indigo .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-indigo .modal-footer .btn-link:hover, .modal-col-indigo .modal-footer .btn-link:active, .modal-col-indigo .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-blue {
  background-color: #2196F3; }
  .modal-col-blue .modal-body,
  .modal-col-blue .modal-title {
    color: #fff !important; }
  .modal-col-blue .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-blue .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-blue .modal-footer .btn-link:hover, .modal-col-blue .modal-footer .btn-link:active, .modal-col-blue .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-light-blue {
  background-color: #03A9F4; }
  .modal-col-light-blue .modal-body,
  .modal-col-light-blue .modal-title {
    color: #fff !important; }
  .modal-col-light-blue .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-light-blue .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-light-blue .modal-footer .btn-link:hover, .modal-col-light-blue .modal-footer .btn-link:active, .modal-col-light-blue .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-cyan {
  background-color: #00BCD4; }
  .modal-col-cyan .modal-body,
  .modal-col-cyan .modal-title {
    color: #fff !important; }
  .modal-col-cyan .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-cyan .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-cyan .modal-footer .btn-link:hover, .modal-col-cyan .modal-footer .btn-link:active, .modal-col-cyan .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-teal {
  background-color: #009688; }
  .modal-col-teal .modal-body,
  .modal-col-teal .modal-title {
    color: #fff !important; }
  .modal-col-teal .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-teal .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-teal .modal-footer .btn-link:hover, .modal-col-teal .modal-footer .btn-link:active, .modal-col-teal .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-green {
  background-color: #4CAF50; }
  .modal-col-green .modal-body,
  .modal-col-green .modal-title {
    color: #fff !important; }
  .modal-col-green .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-green .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-green .modal-footer .btn-link:hover, .modal-col-green .modal-footer .btn-link:active, .modal-col-green .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-light-green {
  background-color: #8BC34A; }
  .modal-col-light-green .modal-body,
  .modal-col-light-green .modal-title {
    color: #fff !important; }
  .modal-col-light-green .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-light-green .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-light-green .modal-footer .btn-link:hover, .modal-col-light-green .modal-footer .btn-link:active, .modal-col-light-green .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-lime {
  background-color: #CDDC39; }
  .modal-col-lime .modal-body,
  .modal-col-lime .modal-title {
    color: #fff !important; }
  .modal-col-lime .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-lime .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-lime .modal-footer .btn-link:hover, .modal-col-lime .modal-footer .btn-link:active, .modal-col-lime .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-yellow {
  background-color: #ffe821; }
  .modal-col-yellow .modal-body,
  .modal-col-yellow .modal-title {
    color: #fff !important; }
  .modal-col-yellow .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-yellow .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-yellow .modal-footer .btn-link:hover, .modal-col-yellow .modal-footer .btn-link:active, .modal-col-yellow .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-amber {
  background-color: #FFC107; }
  .modal-col-amber .modal-body,
  .modal-col-amber .modal-title {
    color: #fff !important; }
  .modal-col-amber .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-amber .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-amber .modal-footer .btn-link:hover, .modal-col-amber .modal-footer .btn-link:active, .modal-col-amber .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-orange {
  background-color: #FF9800; }
  .modal-col-orange .modal-body,
  .modal-col-orange .modal-title {
    color: #fff !important; }
  .modal-col-orange .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-orange .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-orange .modal-footer .btn-link:hover, .modal-col-orange .modal-footer .btn-link:active, .modal-col-orange .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-deep-orange {
  background-color: #FF5722; }
  .modal-col-deep-orange .modal-body,
  .modal-col-deep-orange .modal-title {
    color: #fff !important; }
  .modal-col-deep-orange .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-deep-orange .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-deep-orange .modal-footer .btn-link:hover, .modal-col-deep-orange .modal-footer .btn-link:active, .modal-col-deep-orange .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-brown {
  background-color: #795548; }
  .modal-col-brown .modal-body,
  .modal-col-brown .modal-title {
    color: #fff !important; }
  .modal-col-brown .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-brown .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-brown .modal-footer .btn-link:hover, .modal-col-brown .modal-footer .btn-link:active, .modal-col-brown .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-grey {
  background-color: #9E9E9E; }
  .modal-col-grey .modal-body,
  .modal-col-grey .modal-title {
    color: #fff !important; }
  .modal-col-grey .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-grey .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-grey .modal-footer .btn-link:hover, .modal-col-grey .modal-footer .btn-link:active, .modal-col-grey .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-blue-grey {
  background-color: #607D8B; }
  .modal-col-blue-grey .modal-body,
  .modal-col-blue-grey .modal-title {
    color: #fff !important; }
  .modal-col-blue-grey .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-blue-grey .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-blue-grey .modal-footer .btn-link:hover, .modal-col-blue-grey .modal-footer .btn-link:active, .modal-col-blue-grey .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-black {
  background-color: #000000; }
  .modal-col-black .modal-body,
  .modal-col-black .modal-title {
    color: #fff !important; }
  .modal-col-black .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-black .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-black .modal-footer .btn-link:hover, .modal-col-black .modal-footer .btn-link:active, .modal-col-black .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }

.modal-col-white {
  background-color: #ffffff; }
  .modal-col-white .modal-body,
  .modal-col-white .modal-title {
    color: #fff !important; }
  .modal-col-white .modal-footer {
    background-color: rgba(0, 0, 0, 0.12); }
    .modal-col-white .modal-footer .btn-link {
      color: #fff !important; }
      .modal-col-white .modal-footer .btn-link:hover, .modal-col-white .modal-footer .btn-link:active, .modal-col-white .modal-footer .btn-link:focus {
        background-color: rgba(0, 0, 0, 0.12); }
